import React from "react"
import styled from "styled-components"

import colors from "../styles/colors"
import { Container } from "../styles/global"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/Footer/index"

import SliderMobile from "../components/NossaEmpresa/Slider"

import DistribuidoresParceiros from "../components/DistribuidoresParceiros"
import PrincipaisParceiros from "../components/PrincipaisParceiros"

import AdrakIcon from "../images/NossaEmpresa/ardrak-icon.svg"
import MapaBrasilImg from "../components/NossaEmpresa/MapaImage"
import ImageOne from "../components/NossaEmpresa/ImageOne"
import ImageTwo from "../components/NossaEmpresa/ImageTwo"
import ImageThree from "../components/NossaEmpresa/ImageThree"

import ImageNHOne from "../components/NossaEmpresa/ImageNHOne"
import ImageNHTwo from "../components/NossaEmpresa/ImageNHTwo"
import ImageNHThree from "../components/NossaEmpresa/ImageNHThree"
import ImageNHFour from "../components/NossaEmpresa/ImageNHFour"
import ImageNHFive from "../components/NossaEmpresa/ImageNHFive"

const NossaEmpresa = () => (
  <>
    <SEO title="Conheça a nossa empresa" />
    <Header />
    <Container>
      <SectionOne>
        <div className="left">
          <img src={AdrakIcon} alt="ardrak" />
          <h1>Lider nacional</h1>
          <p>
            A Ardrak é líder nacional e destaque mundial na industrialização e
            comercialização de produtos à base de gengibre, tem orgulho em
            preservar seu lema: "saúde que vem da natureza".
          </p>
        </div>

        <div className="right">
          <MapaBrasilImg />
        </div>
      </SectionOne>
      <SectionTwo>
        <div className="sub-section-img-right">
          <div className="left">
            <h2>29 anos</h2>
          </div>

          <div className="right">
            <ImageOne />
          </div>
        </div>

        <div className="sub-section-img-left">
          <div className="left">
            <ImageTwo />
          </div>

          <div className="right">
            <p>
              Com 29 anos de história, a nossa empresa é definida como a maior
              processadora de gengibre do país, oferecendo aos seus consumidores
              produtos da mais alta qualidade e se orgulhando por ser sinônimo
              de inovação e pioneirismo no setor de alimentos.
            </p>
          </div>
        </div>

        <div className="sub-section-img-right">
          <div className="left">
            <h3>Em constante crescimento.</h3>
          </div>

          <div className="right">
            <ImageThree />
          </div>
        </div>
      </SectionTwo>
      <SectionThree>
        <h2>Nossa história</h2>
        <SliderMobile />

        <div className="content">
          <div className="left">
            <div className="img-one">
              <ImageNHOne />
            </div>
            <div className="img-two">
              <ImageNHTwo />
            </div>

            <div className="img-three">
              <ImageNHThree />
            </div>

            <div className="img-four">
              <ImageNHFour />
            </div>

            <div className="img-five">
              <ImageNHFive />
            </div>
          </div>
          <div className="right">
            <p>
              Criada em 1991, a Ardrak surgiu de um acaso do destino, quando
              Marco Aurélio Duarte, presidente e fundador da empresa, foi
              acometido de uma forte infecção de garganta. Por ser conhecedor da
              cultura indiana e lá ter passado um tempo de sua vida, ele começou
              a utilizar o gengibre em conserva para tentar reverter o quadro de
              sua infecção.
            </p>
            <p>
              Como as conservas não eram práticas para a utilização diária, ele
              teve a ideia de desidratar o gengibre com sal e limão. Nesse
              momento, foram criados os cristais de gengibre, um dos produtos
              favoritos dos nossos consumidores.
            </p>
            <p>
              Posteriormente, adicionou aos cristais cravo, canela e alfavaca,
              cada um com sua função fitoterápica. Curado da infecção, Marco
              Aurélio passou a utilizar sua criação no cotidiano e os amigos,
              que provavam e constatavam os benefícios dos produtos, foram se
              tornando os primeiros clientes até o momento em que a produção
              caseira não atendia mais a demanda.
            </p>
            <p>
              Foi fundada então, em 1991, a firma individual Marco Aurélio
              Duarte e, em 1994, a Ardrak Indústria e Comércio. Imp. Exp. de
              Produtos Naturais Ltda, com sede na cidade de Hidrolândia, vizinha
              da capital Goiânia.
            </p>
            <p>
              À medida em que o espaço físico crescia, os produtos também
              aumentavam. Mais uma vez o sucesso foi absoluto e a empresa
              convidada a participar da feira SIAL em Paris, onde lançou
              mundialmente os Cristais de Gengibre.
            </p>
            <p>
              Ao participar da FISPAL (maior feira do setor alimentício da
              América Latina), em junho de 1997, na cidade de São Paulo, a
              Ardrak confirmou o sucesso dos seus produtos em escala
              internacional.
            </p>
            <p>
              Em 2021, a Ardrak chega aos seus 30 anos como a maior processadora
              de gengibre do país, oferecendo aos seus consumidores produtos da
              mais alta qualidade e se orgulhando por ser sinônimo de inovação e
              pioneirismo no setor de alimentos. Nossa linha de produtos conta
              com os cristais de gengibre, néctar, energéticos, balas e muito
              mais.
            </p>
            <p>Saúde que vem da natureza!</p>
          </div>
        </div>
      </SectionThree>
      <DistribuidoresParceiros />
      <PrincipaisParceiros />
    </Container>
    <Footer />
  </>
)

const SectionOne = styled.section`
  margin-top: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  div.left {
    width: 100%;
    max-width: 50%;

    img {
      margin-bottom: 40px;
      max-width: 114px;
    }

    h1 {
      color: ${colors.VERDE_ESCURO};
      font-size: 80px;
      font-weight: 500;
    }

    p {
      color: #000000;
      max-width: 436px;
      font-size: 28px;
      line-height: 32px;
    }
  }

  div.right {
    width: 100%;
    max-width: 625px;
  }

  @media (max-width: 1220px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: 970px) {
    div.left {
      img {
        max-width: 90px;
        margin-bottom: 30px;
      }
      h1 {
        font-size: 70px;
      }
      p {
        font-size: 25px;
        line-height: 28px;
      }
    }
    div.right {
      width: 100%;
      max-width: 45%;
    }
  }

  @media (max-width: 850px) {
    flex-direction: column-reverse;

    div.left {
      max-width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        display: none;
      }
      h1 {
        text-align: center;
        font-size: 60px;
      }
      p {
        font-size: 25px;
        line-height: 28px;
        text-align: center;
        margin-bottom: 40px;
      }
    }
    div.right {
      max-width: 450px;
      margin-bottom: 40px;
    }
  }
`

const SectionTwo = styled.section`
  margin-top: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.sub-section-img-right {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      color: ${colors.VERDE_ESCURO};
      font-size: 80px;
      font-weight: 500;
    }

    h3 {
      color: ${colors.VERDE_ESCURO};
      font-size: 61px;
      text-align: center;
      font-weight: 500;
    }

    div.left {
      width: 100%;
      max-width: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: #000000;
        max-width: 436px;
        font-size: 28px;
        line-height: 32px;
      }
    }

    div.right {
      width: 100%;
      max-width: 625px;
    }
  }

  div.sub-section-img-left {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    div.left {
      width: 100%;
      max-width: 625px;
    }

    div.right {
      width: 100%;
      max-width: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        color: ${colors.VERDE_ESCURO};
        font-size: 80px;
        font-weight: 500;
      }

      p {
        color: #000000;
        max-width: 436px;
        font-size: 28px;
        line-height: 32px;
      }
    }
  }

  @media (max-width: 1000px) {
    margin-top: 80px;

    div.sub-section-img-right {
      h2 {
        font-size: 70px;
      }

      h3 {
        font-size: 40px;
        line-height: 42px;
        text-align: center;
      }

      div.left {
        p {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    div.sub-section-img-left {
      div.right {
        padding-left: 20px;
        padding-right: 20px;

        h2 {
          font-size: 70px;
        }

        p {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 650px) {
    margin-top: 80px;

    div.sub-section-img-left {
      width: 100%;
      flex-direction: column-reverse;

      div.right {
        background-color: #f6f6f6;
        width: 100%;
        max-width: 100%;
        height: 400px;
      }
    }

    div.sub-section-img-right {
      width: 100%;
      flex-direction: column;

      div.left {
        background-color: #f6f6f6;
        width: 100%;
        max-width: 100%;
        height: 400px;
      }
    }
  }

  @media (max-width: 600px) {
    margin-top: 60px;
  }
`

const SectionThree = styled.section`
  margin-top: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    color: ${colors.VERDE_ESCURO};
    font-size: 80px;
    text-align: center;
    font-weight: 500;

    margin-bottom: 40px;
  }

  div.content {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    div.left {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      width: 100%;
      max-width: 400px;
      margin-right: 30px;

      div {
        margin-bottom: 10px;
      }

      div.img-one {
        width: 100%;
        max-width: 200px;
      }

      div.img-two {
        width: 100%;
        max-width: 401px;
      }

      div.img-three {
        width: 100%;
        max-width: 320px;
      }

      div.img-four {
        width: 100%;
        max-width: 400px;
      }

      div.img-five {
        width: 100%;
        max-width: 364px;
      }
    }

    div.right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      width: 100%;
      max-width: 50%;

      p {
        color: #000000;
        font-size: 26px;
        line-height: 29.5px;
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 1000px) {
    margin-top: 100px;

    div.content {
      div.right {
        p {
          font-size: 22px;
          line-height: 25px;
        }
      }
    }
  }

  @media (max-width: 850px) {
    margin-top: 100px;

    div.content {
      div.right {
        p {
          font-size: 20px;
          line-height: 24px;
          padding-right: 20px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    margin-top: 100px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2 {
      font-size: 60px;
      text-align: left;
      padding-left: 20px;
      margin-bottom: 20px;
    }

    div.content {
      div.left {
        display: none;
      }

      div.right {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        p {
          font-size: 18px;
          line-height: 22px;
          padding-right: 20px;
        }
      }
    }
  }
`

export default NossaEmpresa
