import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import ImageNHOne from "../ImageNHOne"
import ImageNHTwo from "../ImageNHTwo"
import ImageNHThree from "../ImageNHThree"
import ImageNHFour from "../ImageNHFour"
import ImageNHFive from "../ImageNHFive"

import styled from "styled-components"

export default function Slider() {
  return (
    <Container>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        emulateTouch
        centerMode={false}
        autoPlay={false}
        showArrows={false}
        showIndicators={true}
      >
        <ImageNHOne />

        <ImageNHTwo />

        <ImageNHThree />

        <ImageNHFour />

        <ImageNHFive />
      </Carousel>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;

  display: none;

  .carousel .slide {
    background: white;
  }
  .control-dots {
    display: none;
  }

  .carousel .control-dots .dot {
    display: none;
  }

  @media (max-width: 700px) {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
  }
`
